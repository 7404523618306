body {
  background-color: gainsboro;
}

.container {
    padding: 20px;
}

.sale {
    font-weight: bold;
}

.profit {
    color: green;
}

.loss {
    color: darkred;
}

.MuiDataGrid-root {
    background-color: white;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.card {
  max-width: 600px;
  padding: 20px;
  margin: 0 auto;
}

.card-buttons {
 position: relative;
 left: 40%;
 padding: 20px;
}

.portfolio {
    height: calc(100vh - 105px);
}